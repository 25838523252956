import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@mui/material'

import UserRoleLevel from 'constants/user-role-level'
import { lazy, useState } from 'react'
import { Provider, shallowEqual, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { BASE_THEME } from './constants/theme'
import { initTranslations } from './languages'
import { persistor, store } from './redux/store'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

// Hooks
import useAppInit from 'hooks/useAppInit'

// Components
import ProtectedAuth from 'components/auth/ProtectedAuth'
import Loadable from 'components/Loadable'
import ListApp from 'pages/listApp/ListApp'
import EditRole from 'pages/role-portal/components/EditRole'
import ProtechPath from 'components/auth/ProtechPath'
import { LIST_PERMISSION } from 'constants/common'
// import ChoosingApp from '../src/pages/choosingApp'

const ChoosingApp = Loadable(lazy(() => import('pages/ChoosingApp')))
const PageNotFound = Loadable(lazy(() => import('pages/404')))
const Login = Loadable(lazy(() => import('pages/auth/Login')))
const StorePortalListing = Loadable(lazy(() => import('pages/store/StoreListing')))
const UserProfile = Loadable(lazy(() => import('pages/user/UserProfile')))
const UserPortalListing = Loadable(lazy(() => import('pages/user/UserListing')))
const GroupListing = Loadable(lazy(() => import('pages/Group')))
const UserInGroup = Loadable(lazy(() => import('pages/Group/UserListing')))
const UserInRole = Loadable(lazy(() => import('pages/role-portal/UserListing')))

const Services = Loadable(lazy(() => import('pages/Services')))
const RolePortal = Loadable(lazy(() => import('pages/role-portal')))
const Organization = Loadable(lazy(() => import('pages/Organization')))
const UserInOrganization = Loadable(lazy(() => import('pages/Organization/components/UserListing')))

initTranslations()

const AppRoutes = () => {
  const { messages } = useSelector((state) => state.layout, shallowEqual)
  const [isLoading, setIsLoading] = useState(true)
  useAppInit(setIsLoading)
  return (
    !isLoading && (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/404" element={<PageNotFound />} />

            <Route path="/login" element={<Login className="login" />} />

            <Route element={<ProtectedAuth />}>
              {/* <Route path="/" exact element={<MainLayout />}> */}
              {/* <Route path="profile" element={<UserProfile />} /> */}
              {/* </Route> */}

              <Route path="/" exact element={<ChoosingApp />}>
                <Route path="/" element={<ListApp />} />
                <Route path="/user/profile" element={<UserProfile />} />

                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'groups'} />}>
                  <Route path="/group" element={<GroupListing />} />
                  <Route path="/group/users/:id" element={<UserInGroup />} />
                </Route>

                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'services'} />}>
                  <Route path="/services" element={<Services />} />
                </Route>

                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'users'} />}>
                  <Route path="/user-portal" element={<UserPortalListing />} />
                </Route>

                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'stores'} />}>
                  <Route path="/store-portal" element={<StorePortalListing />} />
                </Route>

                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'organizationunits'} />}>
                  <Route path="/organization" element={<Organization />} />
                  <Route path="/organization/users/:id" element={<UserInOrganization />} />
                </Route>
                
                <Route element={<ProtechPath role={LIST_PERMISSION.READ} module={'group_permissions'} />}>
                  <Route path="/role-portal" element={<RolePortal />} />
                  <Route path="/role-portal/edit/:id" element={<EditRole />} />
                  <Route path="/role-portal/users/:id" element={<UserInRole />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </BrowserRouter>

        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <div sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            {Object.values(messages)
              .sort((a, b) => a.createdAt > b.createdAt)
              .map((msg, index) => (
                <Alert
                  variant="outlined"
                  severity={msg.type}
                  key={`notification-message-${index}`}
                  icon={msg.type === 'success' ? <CheckCircleIcon /> : <CancelIcon />}
                  sx={{
                    width: '100%',
                    mb: 1,
                    backgroundColor: `${msg.type === 'success' ? '#ECFDF5' : '#FFF1F2'}`,
                    border: `${msg.type === 'success' ? '1px solid #A7F3D0' : '1px solid #FECDD3'}`,
                    '& .MuiAlert-icon': {
                      marginRight: '10px',
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    },
                    '& .MuiAlert-message': {
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      fontFamily: 'Be Vietnam Pro, sans-serif',
                      fontStyle: 'normal',
                      color: '#333333',
                    },
                  }}
                >
                  {msg.content}
                </Alert>
              ))}
          </div>
        </Snackbar>
      </>
    )
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

const Home = () => {
  return <Navigate to="/app" />
}

export default App
