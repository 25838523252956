import moment from 'moment'
import { useLayoutEffect } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { userActions } from 'redux/slices/userSlice'
import * as roleService from 'services/role.service'
import Cookies from 'utils/cookies'
import LocalStorage from 'utils/localStorage'

function useAppInit(setIsLoading) {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roles = await LocalStorage.getDataList('roles')

        if (!roles) {
          const res = await roleService.getRoles()
          await LocalStorage.setDataList('roles', {
            data: res,
            expiredTime: moment().add(30, 'd').format(),
          })
        }
      } catch (error) {}
    }

    const init = async () => {
      const token = await Cookies.getToken()

      if (token) {
        dispatch(
          userActions.getUserToken({
            onSuccess: () => {
              setIsLoading(false)
              fetchData()
            },
            onError: () => {
              setIsLoading(false)
            },
          })
        )
        
      } else {
        setIsLoading(false)
      }
    }

    init()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get('redirect_url');
  
    if(redirectUrl){
      Cookies.set('redirect_url', redirectUrl)
    }
  }, [])
}

export default useAppInit
